<template>
  <v-app>
    <v-content>
      <v-container
        class="fill-height"
        style="max-width:980px"
      >
        <router-view />
      </v-container>
    </v-content>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :bottom="true"
      :multi-line="snackbar.multiline"
      :timeout="6000"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        text
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>

    export default {
        name: 'App',
        components: {},
        data: () => ({
            snackbar: {
                show: false,
                text: null,
                color: 'blue-grey darken-4',
                multiline: false,
            },
        }),
        mounted() {
            let _this = this;
            window.__notify = function (message, multiline = false) {
                _this.notify(message, multiline);
            };
        },
        methods: {
            notify(text, multiline = false) {
                if (typeof text === "object" && text.text) {
                    text = text.text;
                }
                this.snackbar.text = text;
                this.snackbar.show = true;
                this.snackbar.multiline = text.toString().toLowerCase().startsWith('error') || multiline;
            },
        }
    };
</script>

<style>
  .v-text-field__details {
    margin: 0 !important;
  }

  .v-form .v-text-field input {
    font-size: 13px !important;
  }

</style>
