<template>
  <v-card class="my-10">
    <v-card-title class="title">
      User Final Redirect
    </v-card-title>
    <v-card-text class="px-4">
      <v-card
        outlined
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <div>
              <code class="p-1 ma-1 text--primary">status</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">orderID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">merchantOrderID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1 text--primary">MerchantSecretKey</code>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-1" />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              {{ stringToSign }}
            </v-list-item-title>
            <v-list-item-subtitle>String to sign</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              <v-chip style="cursor:text;">
                {{ signature }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>Signature</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-form class="mt-10">
        <v-row>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.status"
              label="status"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.orderID"
              label="orderID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.merchantOrderID"
              label="merchantOrderID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-snackbar
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn
        color="pink lighten-1"
        text
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
    export default {
        name: "UserFinalRedirect",
        props: {
            secretKey: {
              type: String,
              default: 'SUPER-SECRET-MERCHANT-KEY'
            }
        },
        data() {
            return {
                snackbar: {
                    show: false,
                    text: null,
                },
                apiBaseUrl: "https://api.zotapay-sandbox.com/api/v1",
                form: this.getForm(),
            }
        },
        computed: {
            stringToSign() {
                let f = this.form;
                return f.status + f.orderID + f.merchantOrderID + this.secretKey;
            },
            signature() {
                return this.sha256(this.stringToSign);
            },
        },
        methods: {
            getForm() {
                return {
                    status: "APPROVED",
                    orderID: this.randString(40),
                    merchantOrderID: this.randString(12),
                }
            },
        },
    }
</script>

