<template>
  <v-card class="my-10">
    <v-card-title class="title">
      Order Status Request
    </v-card-title>
    <v-card-text class="px-4">
      <v-card
        outlined
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <div>
              <code class="p-1 ma-1 text--primary">MerchantID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">merchantOrderID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">orderID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">timestamp</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1 text--primary">MerchantSecretKey</code>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-1" />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              {{ stringToSign }}
            </v-list-item-title>
            <v-list-item-subtitle>String to sign</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              <v-chip style="cursor:text;">
                {{ signature }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>Signature</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card
        id="curl"
        class="py-2 my-10"
        dark
        data-clipboard-target="#curl pre"
        @click="copyCurl"
      >
        <v-card-text>
          <pre>
            <span>curl -X GET</span>
             '<span>{{ apiBaseUrl }}/query/order-status/?</span>{{ requestBody }}'
          </pre>
        </v-card-text>
      </v-card>
      <v-form>
        <v-row>
          <v-col
            class="py-0"
            :cols="12"
          >
            <v-text-field
              v-model="form.merchantID"
              label="Merchant ID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.merchantOrderID"
              label="merchantOrderID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.orderID"
              label="orderID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :lg="4"
          >
            <v-text-field
              v-model="form.timestamp"
              label="timestamp"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-snackbar
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn
        color="pink lighten-1"
        text
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
    import Clipboard from 'clipboard'

    export default {
        name: "OrderStatusRequest",
        props: {
            secretKey: {
            type: String,
            default: 'SUPER-SECRET-MERCHANT-KEY'
            }
        },
        data() {
            return {
                snackbar: {
                    show: false,
                    text: null,
                },
                apiBaseUrl: "https://api.zotapay-sandbox.com/api/v1",
                form: this.getForm(),
            }
        },
        computed: {
            stringToSign() {
                let f = this.form;
                return f.merchantID + f.merchantOrderID + f.orderID + f.timestamp + this.secretKey;
            },
            signature() {
                return this.sha256(this.stringToSign);
            },
            requestBody() {
                let f = this.form;
                return `merchantID=${f.merchantID}&merchantOrderID=${f.merchantOrderID}&orderID=${f.orderID}&timestamp=${f.timestamp}&signature=${this.signature}`;
            },
        },
        mounted() {
            const qs = new URLSearchParams(window.location.search);
            const baseUrl = qs.get('base');
            if (baseUrl) {
                this.apiBaseUrl = baseUrl;
            }
            //
            new Clipboard('#curl');
        },
        methods: {
            getForm() {
                return {
                    merchantID: "MERCHANT-ID",
                    merchantOrderID: this.randString(12),
                    orderID: this.randString(40),
                    timestamp: Math.floor(Date.now() / 1000).toString(),
                }
            },
            copyCurl() {
                this.snackbar.text = "Copied To Clipboard";
                this.snackbar.show = true;
            }
        }
    }
</script>

