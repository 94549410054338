<template>
  <v-card class="my-10">
    <v-card-title class="title">
      Orders Report Request
    </v-card-title>
    <v-card-text class="px-4">
      <v-card
        outlined
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <div>
              <code class="p-1 ma-1 text--primary">MerchantID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">dateType</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">endpointIds</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">fromDate</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">requestID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">statuses</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">timestamp</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">toDate</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">types</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1 text--primary">MerchantSecretKey</code>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-1" />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              {{ stringToSign }}
            </v-list-item-title>
            <v-list-item-subtitle>String to sign</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              <v-chip style="cursor:text;">
                {{ signature }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>Signature</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card
        id="curl"
        class="py-2 my-10"
        dark
        data-clipboard-target="#curl pre"
        @click="copyCurl"
      >
        <v-card-text>
          <pre>
            <span>curl -X GET</span>
             '<span>{{ apiBaseUrl }}/query/orders-report/?</span>{{ requestBody }}'
          </pre>
        </v-card-text>
      </v-card>
      <v-form>
        <v-row>
          <v-col
            class="py-0"
            :cols="12"
          >
            <v-text-field
              v-model="form.merchantID"
              label="Merchant ID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-autocomplete
              v-model="form.dateType"
              :items="['created', 'ended']"
              label="dateType"
              placeholder="Select ..."
              outlined
              required
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.fromDate"
              label="fromDate"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
              @blur="validateAndFormatDate('fromDate')"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :sm="6"
            :lg="4"
          >
            <v-text-field
              v-model="form.toDate"
              label="toDate"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
              @blur="validateAndFormatDate('toDate')"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :lg="6"
          >
            <v-autocomplete
              v-model="form.types"
              :items="['SALE', 'PAYOUT', 'REVERSAL']"
              label="types"
              placeholder="Select ..."
              outlined
              required
              filled
              multiple
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :lg="6"
          >
            <v-autocomplete
              v-model="form.statuses"
              :items="['CREATED','AUTHORIZED','PROCESSING','PENDING','APPROVED','DECLINED','FILTERED','UNKNOWN','ERROR']"
              label="statuses"
              placeholder="Select ..."
              outlined
              required
              filled
              multiple
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
          >
            <v-text-field
              v-model="form.endpointIds"
              label="endpointIds ( e.g 1001,1002,1003 )"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :lg="6"
          >
            <v-text-field
              v-model="form.requestID"
              label="requestID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :lg="6"
          >
            <v-text-field
              v-model="form.timestamp"
              label="timestamp"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-snackbar
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn
        color="pink lighten-1"
        text
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
    import Clipboard from 'clipboard'
    import uuid from 'uuid4'

    export default {
        name: "OrderStatusRequest",
        props: {
            secretKey: {
              type: String,
              default: 'SUPER-SECRET-MERCHANT-KEY'
            }
        },
        data() {
            return {
                snackbar: {
                    show: false,
                    text: null,
                },
                apiBaseUrl: "https://api.zotapay-sandbox.com/api/v1",
                form: this.getForm(),
            }
        },
        computed: {
            stringToSign() {
                let f = this.form;
                return f.merchantID + f.dateType + f.endpointIds + f.fromDate + f.requestID + f.statuses.join(',') + f.timestamp + f.toDate + f.types.join(',') + this.secretKey;
            },
            signature() {
                return this.sha256(this.stringToSign);
            },
            requestBody() {
                let f = this.form;
                return `merchantID=${f.merchantID}&dateType=${f.dateType}&endpointIds=${f.endpointIds}&fromDate=${f.fromDate}&requestID=${f.requestID}&statuses=${f.statuses.join(',')}&timestamp=${f.timestamp}&toDate=${f.toDate}&types=${f.types.join(',')}&signature=${this.signature}`
            },
        },
        mounted() {
            const qs = new URLSearchParams(window.location.search);
            const baseUrl = qs.get('base');
            if (baseUrl) {
                this.apiBaseUrl = baseUrl;
            }
            //
            new Clipboard('#curl');
        },
        methods: {
            getForm() {
                return {
                    merchantID: "MERCHANT-ID",
                    dateType: "created",
                    fromDate: this.formatDate(new Date()),
                    toDate: this.formatDate(new Date()),
                    types: ["SALE", "PAYOUT"],
                    statuses: ["APPROVED"],
                    endpointIds: "",
                    requestID: uuid(),
                    timestamp: Math.floor(Date.now() / 1000).toString(),
                }
            },
            copyCurl() {
                this.snackbar.text = "Copied To Clipboard";
                this.snackbar.show = true;
            },
            formatDate(d) {
              const year = d.getFullYear();
              const month = String(d.getMonth() + 1).padStart(2, '0');
              const day = String(d.getDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
            },
            validateAndFormatDate(field) {
              const date = this.form[field];
              const regex = /^\d{4}-\d{1,2}-\d{1,2}$/;

              if (regex.test(date)) {
                // split the date and format it correctly
                const [year, month, day] = date.split('-');
                this.form[field] = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
              } else {
                console.error('Invalid date format');
              }
            }
        }
    }
</script>
