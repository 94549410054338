import Vue from 'vue'
import sha256 from 'sha256'


Vue.mixin({
    data() {
        return {
            allCurrencies: [
                {text: "EUR", value: "EUR"},
                {text: "USD", value: "USD"},
                {text: "BTC", value: "BTC"},
                {text: "ETH", value: "ETH"},
                {text: "USDT", value: "USDT"},
                {text: "CNY", value: "CNY"},
                {text: "AFN", value: "AFN"},
                {text: "DZD", value: "DZD"},
                {text: "ARS", value: "ARS"},
                {text: "AMD", value: "AMD"},
                {text: "AWG", value: "AWG"},
                {text: "AUD", value: "AUD"},
                {text: "AZN", value: "AZN"},
                {text: "BSD", value: "BSD"},
                {text: "BHD", value: "BHD"},
                {text: "THB", value: "THB"},
                {text: "PAB", value: "PAB"},
                {text: "BBD", value: "BBD"},
                {text: "BYR", value: "BYR"},
                {text: "BZD", value: "BZD"},
                {text: "BMD", value: "BMD"},
                {text: "VEF", value: "VEF"},
                {text: "BOB", value: "BOB"},
                {text: "BRL", value: "BRL"},
                {text: "BND", value: "BND"},
                {text: "BGN", value: "BGN"},
                {text: "BIF", value: "BIF"},
                {text: "CVE", value: "CVE"},
                {text: "CAD", value: "CAD"},
                {text: "KYD", value: "KYD"},
                {text: "XOF", value: "XOF"},
                {text: "XAF", value: "XAF"},
                {text: "XPF", value: "XPF"},
                {text: "CLP", value: "CLP"},
                {text: "COP", value: "COP"},
                {text: "KMF", value: "KMF"},
                {text: "CDF", value: "CDF"},
                {text: "BAM", value: "BAM"},
                {text: "NIO", value: "NIO"},
                {text: "CRC", value: "CRC"},
                {text: "CUP", value: "CUP"},
                {text: "CZK", value: "CZK"},
                {text: "GMD", value: "GMD"},
                {text: "DKK", value: "DKK"},
                {text: "MKD", value: "MKD"},
                {text: "DJF", value: "DJF"},
                {text: "DOP", value: "DOP"},
                {text: "VND", value: "VND"},
                {text: "XCD", value: "XCD"},
                {text: "EGP", value: "EGP"},
                {text: "SVC", value: "SVC"},
                {text: "ETB", value: "ETB"},
                {text: "FKP", value: "FKP"},
                {text: "FJD", value: "FJD"},
                {text: "HUF", value: "HUF"},
                {text: "GHS", value: "GHS"},
                {text: "GIP", value: "GIP"},
                {text: "HTG", value: "HTG"},
                {text: "PYG", value: "PYG"},
                {text: "GNF", value: "GNF"},
                {text: "GYD", value: "GYD"},
                {text: "HKD", value: "HKD"},
                {text: "UAH", value: "UAH"},
                {text: "ISK", value: "ISK"},
                {text: "INR", value: "INR"},
                {text: "IRR", value: "IRR"},
                {text: "IQD", value: "IQD"},
                {text: "JMD", value: "JMD"},
                {text: "JOD", value: "JOD"},
                {text: "KES", value: "KES"},
                {text: "PGK", value: "PGK"},
                {text: "LAK", value: "LAK"},
                {text: "HRK", value: "HRK"},
                {text: "KWD", value: "KWD"},
                {text: "MWK", value: "MWK"},
                {text: "AOA", value: "AOA"},
                {text: "MMK", value: "MMK"},
                {text: "GEL", value: "GEL"},
                {text: "LBP", value: "LBP"},
                {text: "ALL", value: "ALL"},
                {text: "HNL", value: "HNL"},
                {text: "SLL", value: "SLL"},
                {text: "LRD", value: "LRD"},
                {text: "LYD", value: "LYD"},
                {text: "SZL", value: "SZL"},
                {text: "LSL", value: "LSL"},
                {text: "MGA", value: "MGA"},
                {text: "MYR", value: "MYR"},
                {text: "MUR", value: "MUR"},
                {text: "MXN", value: "MXN"},
                {text: "MDL", value: "MDL"},
                {text: "MAD", value: "MAD"},
                {text: "MZN", value: "MZN"},
                {text: "NGN", value: "NGN"},
                {text: "ERN", value: "ERN"},
                {text: "NAD", value: "NAD"},
                {text: "NPR", value: "NPR"},
                {text: "ANG", value: "ANG"},
                {text: "ILS", value: "ILS"},
                {text: "TWD", value: "TWD"},
                {text: "NZD", value: "NZD"},
                {text: "BTN", value: "BTN"},
                {text: "KPW", value: "KPW"},
                {text: "NOK", value: "NOK"},
                {text: "PEN", value: "PEN"},
                {text: "TOP", value: "TOP"},
                {text: "PKR", value: "PKR"},
                {text: "MOP", value: "MOP"},
                {text: "CUC", value: "CUC"},
                {text: "UYU", value: "UYU"},
                {text: "PHP", value: "PHP"},
                {text: "GBP", value: "GBP"},
                {text: "BWP", value: "BWP"},
                {text: "QAR", value: "QAR"},
                {text: "GTQ", value: "GTQ"},
                {text: "ZAR", value: "ZAR"},
                {text: "OMR", value: "OMR"},
                {text: "KHR", value: "KHR"},
                {text: "RON", value: "RON"},
                {text: "MVR", value: "MVR"},
                {text: "IDR", value: "IDR"},
                {text: "RUB", value: "RUB"},
                {text: "RWF", value: "RWF"},
                {text: "SHP", value: "SHP"},
                {text: "SAR", value: "SAR"},
                {text: "RSD", value: "RSD"},
                {text: "SCR", value: "SCR"},
                {text: "SGD", value: "SGD"},
                {text: "SBD", value: "SBD"},
                {text: "KGS", value: "KGS"},
                {text: "SOS", value: "SOS"},
                {text: "TJS", value: "TJS"},
                {text: "LKR", value: "LKR"},
                {text: "SDG", value: "SDG"},
                {text: "SRD", value: "SRD"},
                {text: "SEK", value: "SEK"},
                {text: "CHF", value: "CHF"},
                {text: "SYP", value: "SYP"},
                {text: "BDT", value: "BDT"},
                {text: "WST", value: "WST"},
                {text: "TZS", value: "TZS"},
                {text: "KZT", value: "KZT"},
                {text: "TTD", value: "TTD"},
                {text: "MNT", value: "MNT"},
                {text: "TND", value: "TND"},
                {text: "TRY", value: "TRY"},
                {text: "TMT", value: "TMT"},
                {text: "AED", value: "AED"},
                {text: "UGX", value: "UGX"},
                {text: "UZS", value: "UZS"},
                {text: "VUV", value: "VUV"},
                {text: "KRW", value: "KRW"},
                {text: "YER", value: "YER"},
                {text: "JPY", value: "JPY"},
                {text: "ZMW", value: "ZMW"},
                {text: "ZWL", value: "ZWL"},
                {text: "PLN", value: "PLN"},
            ],
            allCountries: [
                {text: "AF", value: "AF"},
                {text: "AL", value: "AL"},
                {text: "DZ", value: "DZ"},
                {text: "AS", value: "AS"},
                {text: "AD", value: "AD"},
                {text: "AO", value: "AO"},
                {text: "AI", value: "AI"},
                {text: "AQ", value: "AQ"},
                {text: "AG", value: "AG"},
                {text: "AR", value: "AR"},
                {text: "AM", value: "AM"},
                {text: "AW", value: "AW"},
                {text: "AU", value: "AU"},
                {text: "AT", value: "AT"},
                {text: "AZ", value: "AZ"},
                {text: "BS", value: "BS"},
                {text: "BH", value: "BH"},
                {text: "BD", value: "BD"},
                {text: "BB", value: "BB"},
                {text: "BY", value: "BY"},
                {text: "BE", value: "BE"},
                {text: "BZ", value: "BZ"},
                {text: "BJ", value: "BJ"},
                {text: "BM", value: "BM"},
                {text: "BT", value: "BT"},
                {text: "BO", value: "BO"},
                {text: "BQ", value: "BQ"},
                {text: "BA", value: "BA"},
                {text: "BW", value: "BW"},
                {text: "BV", value: "BV"},
                {text: "BR", value: "BR"},
                {text: "IO", value: "IO"},
                {text: "BN", value: "BN"},
                {text: "BG", value: "BG"},
                {text: "BF", value: "BF"},
                {text: "BI", value: "BI"},
                {text: "CV", value: "CV"},
                {text: "KH", value: "KH"},
                {text: "CM", value: "CM"},
                {text: "CA", value: "CA"},
                {text: "KY", value: "KY"},
                {text: "CF", value: "CF"},
                {text: "TD", value: "TD"},
                {text: "CL", value: "CL"},
                {text: "CN", value: "CN"},
                {text: "CX", value: "CX"},
                {text: "CC", value: "CC"},
                {text: "CO", value: "CO"},
                {text: "KM", value: "KM"},
                {text: "CD", value: "CD"},
                {text: "CG", value: "CG"},
                {text: "CK", value: "CK"},
                {text: "CR", value: "CR"},
                {text: "HR", value: "HR"},
                {text: "CU", value: "CU"},
                {text: "CW", value: "CW"},
                {text: "CY", value: "CY"},
                {text: "CZ", value: "CZ"},
                {text: "CI", value: "CI"},
                {text: "DK", value: "DK"},
                {text: "DJ", value: "DJ"},
                {text: "DM", value: "DM"},
                {text: "DO", value: "DO"},
                {text: "EC", value: "EC"},
                {text: "EG", value: "EG"},
                {text: "SV", value: "SV"},
                {text: "GQ", value: "GQ"},
                {text: "ER", value: "ER"},
                {text: "EE", value: "EE"},
                {text: "SZ", value: "SZ"},
                {text: "ET", value: "ET"},
                {text: "FK", value: "FK"},
                {text: "FO", value: "FO"},
                {text: "FJ", value: "FJ"},
                {text: "FI", value: "FI"},
                {text: "FR", value: "FR"},
                {text: "GF", value: "GF"},
                {text: "PF", value: "PF"},
                {text: "TF", value: "TF"},
                {text: "GA", value: "GA"},
                {text: "GM", value: "GM"},
                {text: "GE", value: "GE"},
                {text: "DE", value: "DE"},
                {text: "GH", value: "GH"},
                {text: "GI", value: "GI"},
                {text: "GR", value: "GR"},
                {text: "GL", value: "GL"},
                {text: "GD", value: "GD"},
                {text: "GP", value: "GP"},
                {text: "GU", value: "GU"},
                {text: "GT", value: "GT"},
                {text: "GG", value: "GG"},
                {text: "GN", value: "GN"},
                {text: "GW", value: "GW"},
                {text: "GY", value: "GY"},
                {text: "HT", value: "HT"},
                {text: "HM", value: "HM"},
                {text: "VA", value: "VA"},
                {text: "HN", value: "HN"},
                {text: "HK", value: "HK"},
                {text: "HU", value: "HU"},
                {text: "IS", value: "IS"},
                {text: "IN", value: "IN"},
                {text: "ID", value: "ID"},
                {text: "IR", value: "IR"},
                {text: "IQ", value: "IQ"},
                {text: "IE", value: "IE"},
                {text: "IM", value: "IM"},
                {text: "IL", value: "IL"},
                {text: "IT", value: "IT"},
                {text: "JM", value: "JM"},
                {text: "JP", value: "JP"},
                {text: "JE", value: "JE"},
                {text: "JO", value: "JO"},
                {text: "KZ", value: "KZ"},
                {text: "KE", value: "KE"},
                {text: "KI", value: "KI"},
                {text: "KP", value: "KP"},
                {text: "KR", value: "KR"},
                {text: "KW", value: "KW"},
                {text: "KG", value: "KG"},
                {text: "LA", value: "LA"},
                {text: "LV", value: "LV"},
                {text: "LB", value: "LB"},
                {text: "LS", value: "LS"},
                {text: "LR", value: "LR"},
                {text: "LY", value: "LY"},
                {text: "LI", value: "LI"},
                {text: "LT", value: "LT"},
                {text: "LU", value: "LU"},
                {text: "MO", value: "MO"},
                {text: "MK", value: "MK"},
                {text: "MG", value: "MG"},
                {text: "MW", value: "MW"},
                {text: "MY", value: "MY"},
                {text: "MV", value: "MV"},
                {text: "ML", value: "ML"},
                {text: "MT", value: "MT"},
                {text: "MH", value: "MH"},
                {text: "MQ", value: "MQ"},
                {text: "MR", value: "MR"},
                {text: "MU", value: "MU"},
                {text: "YT", value: "YT"},
                {text: "MX", value: "MX"},
                {text: "FM", value: "FM"},
                {text: "MD", value: "MD"},
                {text: "MC", value: "MC"},
                {text: "MN", value: "MN"},
                {text: "ME", value: "ME"},
                {text: "MS", value: "MS"},
                {text: "MA", value: "MA"},
                {text: "MZ", value: "MZ"},
                {text: "MM", value: "MM"},
                {text: "NA", value: "NA"},
                {text: "NR", value: "NR"},
                {text: "NP", value: "NP"},
                {text: "NL", value: "NL"},
                {text: "NC", value: "NC"},
                {text: "NZ", value: "NZ"},
                {text: "NI", value: "NI"},
                {text: "NE", value: "NE"},
                {text: "NG", value: "NG"},
                {text: "NU", value: "NU"},
                {text: "NF", value: "NF"},
                {text: "MP", value: "MP"},
                {text: "NO", value: "NO"},
                {text: "OM", value: "OM"},
                {text: "PK", value: "PK"},
                {text: "PW", value: "PW"},
                {text: "PS", value: "PS"},
                {text: "PA", value: "PA"},
                {text: "PG", value: "PG"},
                {text: "PY", value: "PY"},
                {text: "PE", value: "PE"},
                {text: "PH", value: "PH"},
                {text: "PN", value: "PN"},
                {text: "PL", value: "PL"},
                {text: "PT", value: "PT"},
                {text: "PR", value: "PR"},
                {text: "QA", value: "QA"},
                {text: "RO", value: "RO"},
                {text: "RU", value: "RU"},
                {text: "RW", value: "RW"},
                {text: "RE", value: "RE"},
                {text: "BL", value: "BL"},
                {text: "SH", value: "SH"},
                {text: "KN", value: "KN"},
                {text: "LC", value: "LC"},
                {text: "MF", value: "MF"},
                {text: "PM", value: "PM"},
                {text: "VC", value: "VC"},
                {text: "WS", value: "WS"},
                {text: "SM", value: "SM"},
                {text: "ST", value: "ST"},
                {text: "SA", value: "SA"},
                {text: "SN", value: "SN"},
                {text: "RS", value: "RS"},
                {text: "SC", value: "SC"},
                {text: "SL", value: "SL"},
                {text: "SG", value: "SG"},
                {text: "SX", value: "SX"},
                {text: "SK", value: "SK"},
                {text: "SI", value: "SI"},
                {text: "SB", value: "SB"},
                {text: "SO", value: "SO"},
                {text: "ZA", value: "ZA"},
                {text: "GS", value: "GS"},
                {text: "SS", value: "SS"},
                {text: "ES", value: "ES"},
                {text: "LK", value: "LK"},
                {text: "SD", value: "SD"},
                {text: "SR", value: "SR"},
                {text: "SJ", value: "SJ"},
                {text: "SE", value: "SE"},
                {text: "CH", value: "CH"},
                {text: "SY", value: "SY"},
                {text: "TW", value: "TW"},
                {text: "TJ", value: "TJ"},
                {text: "TZ", value: "TZ"},
                {text: "TH", value: "TH"},
                {text: "TL", value: "TL"},
                {text: "TG", value: "TG"},
                {text: "TK", value: "TK"},
                {text: "TO", value: "TO"},
                {text: "TT", value: "TT"},
                {text: "TN", value: "TN"},
                {text: "TR", value: "TR"},
                {text: "TM", value: "TM"},
                {text: "TC", value: "TC"},
                {text: "TV", value: "TV"},
                {text: "UG", value: "UG"},
                {text: "UA", value: "UA"},
                {text: "AE", value: "AE"},
                {text: "GB", value: "GB"},
                {text: "US", value: "US"},
                {text: "UY", value: "UY"},
                {text: "UZ", value: "UZ"},
                {text: "VU", value: "VU"},
                {text: "VE", value: "VE"},
                {text: "VN", value: "VN"},
                {text: "VG", value: "VG"},
                {text: "VI", value: "VI"},
                {text: "WF", value: "WF"},
                {text: "EH", value: "EH"},
                {text: "YE", value: "YE"},
                {text: "ZM", value: "ZM"},
                {text: "ZW", value: "ZW"},
                {text: "AX", value: "AX"},
            ],
            allStates: {
                "US": ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"],
                "AU": ["AC", "NS", "NT", "QL", "SA", "TA", "VI", "WA"],
                "CA": ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"],
            },
        }
    },
    methods: {
        sha256(s) {
            return sha256(s);
        },
        randString(len) {
            let result = '';
            let characters = 'abcdef01234567890123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < len; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    },
});
