<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-container>
      <!---->
      <v-card>
        <v-card-title>
          Request Signature Tools
        </v-card-title>
        <v-card-text>
          Zota Metagate API
        </v-card-text>
        <v-divider />
        <v-card-text class="pb-0">
          <v-row>
            <v-col
              :cols="12"
              :md="6"
            >
              <v-select
                v-model="useCase"
                :items="useCases"
                :rules="[v => !!v || 'Selection is required.']"
                label="Use case"
                placeholder="Select a use-case..."
                item-text="text"
                item-value="id"
                append-icon="mdi-account-lock"
                required
                outlined
              />
            </v-col>
            <v-col
              :cols="12"
              :md="6"
            >
              <v-text-field
                v-model="secretKey"
                append-icon="mdi-shield-key"
                label="Merchant Secret Key"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!---->
      <deposit-request
        v-if="useCase === 'depositRequest'"
        :secret-key="secretKey"
      />
      <payout-request
        v-if="useCase === 'payoutRequest'"
        :secret-key="secretKey"
      />
      <order-status-request
        v-if="useCase === 'orderStatusRequest'"
        :secret-key="secretKey"
      />
      <user-final-redirect
        v-if="useCase === 'userFinalRedirect'"
        :secret-key="secretKey"
      />
      <callback-notification
        v-if="useCase === 'callbackNotification'"
        :secret-key="secretKey"
      />
      <orders-report-request
        v-if="useCase === 'ordersReportRequest'"
        :secret-key="secretKey"
      />
      <orders-exchange-rates-request
        v-if="useCase === 'ordersExchangeRatesRequest'"
        :secret-key="secretKey"
      />
      <!---->
    </v-container>
  </v-container>
</template>

<script>
    import DepositRequest from "../components/DepositRequest";
    import PayoutRequest from "../components/PayoutRequest";
    import OrderStatusRequest from "../components/OrderStatusRequest";
    import UserFinalRedirect from "../components/UserFinalRedirect";
    import CallbackNotification from "../components/CallbackNotification";
    import OrdersReportRequest from "../components/OrdersReportRequest";
    import OrdersExchangeRatesRequest from "../components/OrdersExchangeRatesRequest";

    export default {
        name: "Home",
        components: {CallbackNotification, UserFinalRedirect, OrderStatusRequest, PayoutRequest, DepositRequest, OrdersReportRequest, OrdersExchangeRatesRequest},
        data: () => ({
            loading: false,
            secretKey: "MERCHANT-SECRET-KEY",
            useCase: "depositRequest",
            useCases: [
                {text: "Deposit Request", id: "depositRequest"},
                {text: "Payout Request", id: "payoutRequest"},
                {text: "Order Status Request", id: "orderStatusRequest"},
                {text: "User Final Redirect", id: "userFinalRedirect"},
                {text: "Callback Notification", id: "callbackNotification"},
                {text: "Orders Report Request", id: "ordersReportRequest"},
                {text: "Orders Exchange Rates Request", id: "ordersExchangeRatesRequest"},
            ],
        }),
        methods: {}
    }
</script>

<style>
  input {
    font-size: 14px !important;
  }
  .v-list-item {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
  pre{
    white-space: normal;
  }
  pre span{color:#fff;}
</style>
