<template>
  <v-card class="my-10">
    <v-card-title class="title">
      Payout Request Signature
    </v-card-title>
    <v-card-text class="px-4">
      <v-card
        outlined
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <div>
              <code class="p-1 ma-1 text--primary">EndpointID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">merchantOrderID</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">orderAmount</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">customerEmail</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1">customerBankAccountNumber</code>
              <span class="caption text--secondary">+</span>
              <code class="p-1 ma-1 text--primary">MerchantSecretKey</code>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-1" />
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              {{ stringToSign }}
            </v-list-item-title>
            <v-list-item-subtitle>String to sign</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size:14px">
              <v-chip style="cursor:text;">
                {{ signature }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>Signature</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-card
        id="curl"
        class="py-2 my-10"
        dark
        data-clipboard-target="#curl pre"
        @click="copyCurl"
      >
        <v-card-text>
          <pre>
            <span>curl -X POST -H "Content-Type: application/json" -d</span>
            '{{ requestBody }}'
            <span>{{ apiBaseUrl }}/payout/request/<b>{{ form.endpointID }}</b>/</span>
          </pre>
        </v-card-text>
      </v-card>
      <v-form>
        <v-row>
          <v-col
            class="py-0"
            :cols="12"
            :md="6"
          >
            <v-text-field
              v-model="form.endpointID"
              label="Endpoint ID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="6"
          >
            <v-text-field
              v-model="form.merchantOrderID"
              label="merchantOrderID"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.orderAmount"
              label="orderAmount"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-autocomplete
              v-model="form.orderCurrency"
              :items="allCurrencies"
              :rules="[v => !!v || 'Select a currency']"
              label="orderCurrency"
              placeholder="Select ..."
              item-text="text"
              item-value="value"
              outlined
              required
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.merchantOrderDesc"
              label="merchantOrderDesc"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerEmail"
              label="customerEmail"
              required
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerFirstName"
              label="customerFirstName"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerLastName"
              label="customerLastName"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankAccountNumber"
              label="customerBankAccountNumber"
              filled
              append-icon="mdi-file-key-outline"
              color="indigo"
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankAccountName"
              label="customerBankAccountName"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankCode"
              label="customerBankCode"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-autocomplete
              v-model="form.customerCountryCode"
              :items="allCountries"
              :rules="[v => !!v || 'Select a country']"
              label="customerCountryCode"
              placeholder="Select ..."
              item-text="text"
              item-value="value"
              outlined
              required
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankBranch"
              label="customerBankBranch"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankAddress"
              label="customerBankAddress"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankZipCode"
              label="customerBankZipCode"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankProvince"
              label="customerBankProvince"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankArea"
              label="customerBankArea"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerBankRoutingNumber"
              label="customerBankRoutingNumber"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerPhone"
              label="customerPhone"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customerIP"
              label="customerIP"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.customParam"
              label="customParam"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.redirectUrl"
              label="redirectUrl"
              outlined
            />
          </v-col>
          <v-col
            class="py-0"
            :cols="12"
            :md="4"
          >
            <v-text-field
              v-model="form.callbackUrl"
              label="callbackUrl"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-snackbar
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn
        color="pink lighten-1"
        text
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
    import Clipboard from 'clipboard'

    export default {
        name: "PayoutRequest",
        props: {
            secretKey: {
              type: String,
              default: 'SUPER-SECRET-MERCHANT-KEY'
            }
        },
        data() {
            return {
                snackbar: {
                    show: false,
                    text: null,
                },
                apiBaseUrl: "https://api.zotapay-sandbox.com/api/v1",
                form: this.getForm(),
            }
        },
        computed: {
            stringToSign() {
                let f = this.form;
                return f.endpointID + f.merchantOrderID + f.orderAmount + f.customerEmail + f.customerBankAccountNumber + this.secretKey;
            },
            signature() {
                return this.sha256(this.stringToSign);
            },
            requestBody() {
                let data = JSON.parse(JSON.stringify(this.form));
                delete data.endpointID;
                data.signature = this.signature;
                return JSON.stringify(data);
            },
        },
        mounted() {
            new Clipboard('#curl');
            //
            const qs = new URLSearchParams(window.location.search);
            const baseUrl = qs.get('base');
            if (baseUrl) {
              this.apiBaseUrl = baseUrl;
            }
        },
        methods: {
            getForm() {
                let custID = this.randString(8);
                return {
                    endpointID: "000",
                    merchantOrderID: this.randString(12),
                    merchantOrderDesc: "Test order description",
                    orderAmount: "100.00",
                    orderCurrency: "USD",
                    customerEmail: "customer@just-for-test.com",
                    customerFirstName: "John",
                    customerLastName: "Lock",
                    customerCountryCode: "TH",
                    customerPhone: "+1 420-100-1000",
                    customerIP: "134.201.250.135",
                    redirectUrl: "https://www.example-merchant.com/payout-return/",
                    callbackUrl: "https://www.example-merchant.com/payout-callback/",
                    customerBankCode: "BBL",
                    customerBankAccountNumber: (Math.floor(Math.random() * 999999) + 10000).toString(),
                    customerBankAccountName: "John Doe",
                    customerBankBranch: "Bank Branch",
                    customerBankAddress: "Bank Address",
                    customerBankZipCode: "800400",
                    customerBankProvince: "Bank Province",
                    customerBankArea: "Bank Area / City",
                    customerBankRoutingNumber: "000",
                    customParam: "{\"UserId\": \"" + custID + "\"}",
                    checkoutUrl: `https://www.example-merchant.com/account/withdrawal/?uid=${custID}`,
                }
            },
            copyCurl() {
                this.snackbar.text = "Copied To Clipboard";
                this.snackbar.show = true;
            }
        }
    }
</script>

